export enum SYSTEM_VALUES {
  MEDICATION_NDC_SYSTEM = "https://dps.fda.gov/ndc",
  LIFEFILE_MEDICATION_SYSTEM = "http://lifefile.com/fhir/identifiers/medication",
  DRUG_CHARACTERISTIC_SYSTEM = "http://chartedhealth.com/fhir/medicationknowledge-characteristic",
  MEDICATION_REQUEST_CATEGORY_SYSTEM = "http://chartedhealth.com/fhir/medication-request-category",
  MEDICATION_DISPENSE_TRACKING_CODE = "http://chartedhealth.com/fhir/identifiers/medication-dispense-tracking-code",
  PUBLIC_MEDICATION = "http://chartedhealth.com/fhir/identifiers/public/medication",
  DOCUMENT_REFERENCE_TYPE = "http://chartedhealth.com/fhir/document-reference-type",

  // SERVICE REQUEST RELATED
  ORDER_DETAIL_TYPE = "http://chartedhealth.com/fhir/order-detail-type",
  MEDICATION_ORDER = "http://chartedhealth.com/fhir/identifiers/medication-order",
  LIFEFILE_MEDICATION_ORDER = "http://lifefile.com/fhir/identifiers/order-id",

  //PAYMENT
  INVOICE_INDENTIFIER = "http://chartedhealth.com/fhir/payment/invoice-identifier",
  SKU = "http://chartedhealth.com/fhir/identifiers/sku",
  SKU_CA = "http://chartedhealth.com/fhir/identifiers/sku-ca",
  SERVICE_FEE = "http://chartedhealth.com/fhir/service-fee",
  DISCOUNT = "http://chartedhealth.com/fhir/payment/discount",

  //SHIPPING METHODS
  SHIPPING_METHOD = "http://chartedhealth.com/fhir/shipping-method",
  SHIPPING_METHOD_DEFAULT = "http://chartedhealth.com/fhir/shipping-method-default",
  COVERAGE_TYPE = "http://chartedhealth.com/fhir/coverage-type",
}
