import { format, formatDate, parseISO } from "date-fns"
import { codeableConceptAsString, MedicationDispense, MedicationKnowledge, MedicationRequest, Money } from "fhir"
import pluralize from "pluralize"
import { MenuItem } from "primereact/menuitem"
import { classNames } from "primereact/utils"
import { ReactNode } from "react"

import { Badge, DropdownMenu } from "commons"
import { formatsByTypes } from "data"
import { getBadgeColor, getMoneyCurrencyAlt } from "utils"

import { dispenseInterval } from "../data"
import { MedicationKnowledgeImage } from "./MedicationKnowledgeImage"
import { MedicationUnitsDropdown } from "./MedicationUnitsDropdown"

const MedicationDataItem = ({
  cardStyle,
  vertical,
  medicationKnowledge,
  medicationRequest,
  medicationDispense,
  pricePerUnit,
  showInstructions,
  showPackagingType,
  showStatus,
  showDispense,
  onClick,
  dropdownMenuItems,
  isDropdownLoading,
  footer,
  editUnits,
}: MedicationDataItemProps) => {
  const amount =
    medicationRequest?.dispenseRequest?.quantity?.value ??
    medicationRequest?.dispenseRequest?.initialFill?.quantity?.value ??
    1

  const price = pricePerUnit?.value ? (
    <span title="Price" className="font-semibold text-gray-900">
      {getMoneyCurrencyAlt(pricePerUnit.currency)}
      {pricePerUnit.value.toFixed(2)}
    </span>
  ) : undefined

  const frecuency = dispenseInterval.find(
    (i) =>
      i.value.value !== 0 &&
      i.value.value === medicationRequest?.dispenseRequest?.dispenseInterval?.value &&
      i.value.unit === medicationRequest?.dispenseRequest?.dispenseInterval?.unit,
  )

  const mrNumberOfRepeats = (medicationRequest?.dispenseRequest?.numberOfRepeatsAllowed ?? 0) + 1
  const mrDispensed = medicationDispense?.length ?? 0
  const mrStartDispense =
    medicationRequest?.dispenseRequest?.validityPeriod?.start &&
    formatDate(parseISO(medicationRequest?.dispenseRequest?.validityPeriod?.start), formatsByTypes.SHORT_MONTH_YEAR)

  const status = medicationRequest?.doNotPerform ? "suspended" : (medicationRequest?.status as string)
  const isDfo = medicationRequest?.dispenseRequest?.initialFill?.isDfo ?? false

  return (
    <div
      className={classNames(
        "product-list-item text-sm md:text-base w-full border-b border-slate-300 py-3 p-2",
        cardStyle ? "border rounded-xl" : "last-of-type:border-b-0",
      )}
    >
      <div className="flex gap-2 lg:gap-4 justify-between flex-col">
        <div className="flex gap-2 lg:gap-4 justify-between">
          <div className={classNames("flex gap-1 truncate", vertical ? "flex-col flex-1" : "flex-row")}>
            <span className={classNames("w-32 h-32 m-auto text-center", onClick && "cursor-pointer")} onClick={onClick}>
              <MedicationKnowledgeImage
                drugCharacteristic={medicationKnowledge?.drugCharacteristic}
                className="h-32 m-auto object-contain"
              />
            </span>
            <div className="product-list-detail flex flex-col gap-1 truncate">
              <div className="flex justify-between">
                <div
                  title="Name"
                  className={classNames("font-semibold text-gray-900 truncate", onClick && "cursor-pointer")}
                  onClick={onClick}
                >
                  {codeableConceptAsString(medicationKnowledge?.code ?? medicationRequest?.medication?.CodeableConcept)}
                </div>
                {vertical && price}
              </div>
              {medicationKnowledge?.manufacturer?.id && (
                <div title="Manufacturer" className="text-gray-400 font-medium truncate">
                  {medicationKnowledge.manufacturer.display}
                </div>
              )}
              {medicationKnowledge?.packaging?.quantity &&
                !medicationKnowledge?.packaging?.quantity.unit?.includes("pellet") && (
                  <div title="Packing">
                    {medicationKnowledge.packaging.quantity.value}{" "}
                    {pluralize(
                      medicationKnowledge.packaging.quantity.unit as string,
                      medicationKnowledge.packaging.quantity.value,
                    )}
                  </div>
                )}
              {showInstructions && medicationRequest?.dosageInstruction?.length && (
                <div title="Instructions" className="inline-flex truncate max-w-sm">
                  {medicationRequest.dosageInstruction.map((instruction, index) => (
                    <p className="truncate" key={instruction.id ?? index}>
                      {instruction.text}
                    </p>
                  ))}
                </div>
              )}
              {showPackagingType && !editUnits && medicationKnowledge?.packaging?.type && (
                <div title="Type">
                  <span className="ml-1">
                    {`${amount} ${pluralize(codeableConceptAsString(medicationKnowledge.packaging.type), amount)}`}
                  </span>
                  {frecuency && <span className="ml-1">{frecuency.label.toLocaleLowerCase()}</span>}
                </div>
              )}
              {editUnits && (
                <MedicationUnitsDropdown
                  field="dispenseRequest.initialFill.quantity.value"
                  unitName={codeableConceptAsString(medicationKnowledge?.packaging?.type)}
                  pricePerUnit={pricePerUnit}
                />
              )}
            </div>
          </div>
          {!vertical && (
            <div className="flex flex-col items-end gap-2">
              {dropdownMenuItems && dropdownMenuItems.length > 0 && (
                <DropdownMenu dropdownMenuItems={dropdownMenuItems} loading={isDropdownLoading} />
              )}
              {medicationRequest && (
                <>
                  {showStatus && (
                    <span title="Status">
                      <Badge {...getBadgeColor(status)} />
                    </span>
                  )}
                  {price}
                  {showDispense && (
                    <>
                      {status !== "draft" &&
                        (mrNumberOfRepeats >= 1 && mrDispensed < mrNumberOfRepeats ? (
                          <span
                            title="Dispense"
                            className="text-sm"
                          >{`Dispense ${mrDispensed} of ${mrNumberOfRepeats}`}</span>
                        ) : (
                          <span title="Dispense" className="text-sm">
                            {isDfo
                              ? `Dispensed from office${mrStartDispense ? `: ${mrStartDispense}` : ""}`
                              : `Dispensed ${mrDispensed}${mrStartDispense ? ` since ${mrStartDispense}` : ""}`}
                          </span>
                        ))}
                      {status === "active" && medicationRequest.dispenseRequest?.nextRefillDate && (
                        <span title="Next Refill" className="text-sm">
                          Next refill:{" "}
                          {format(
                            Date.parse(medicationRequest.dispenseRequest.nextRefillDate.replace("+00:00", "")),
                            formatsByTypes.LONG_DATE,
                          )}
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {footer}
      </div>
    </div>
  )
}

export type MedicationDataItemProps = {
  cardStyle?: boolean
  vertical?: boolean
  medicationKnowledge?: MedicationKnowledge
  medicationRequest?: MedicationRequest
  medicationDispense?: MedicationDispense[]
  pricePerUnit?: Money
  showInstructions?: boolean
  showPackagingType?: boolean
  showStatus?: boolean
  showDispense?: boolean
  onClick?(): void
  dropdownMenuItems?: MenuItem[]
  isDropdownLoading?: boolean
  footer?: ReactNode
  editUnits?: boolean
}

export { MedicationDataItem }
