import { MedicationDosage } from "eprescribe/types"
import {
  ChargeItemDefinition,
  Coding,
  MedicationDispense,
  MedicationKnowledge,
  MedicationKnowledgeAdministrationGuidelinesArray,
  MedicationRequest,
  Money,
  ParametersParameterArrayValue,
  Practitioner,
  PractitionerRole,
  Quantity,
  Reference,
  ServiceRequest,
} from "fhir"

export type FormatTypes =
  | "ISO_8601_DATE"
  | "ISO_8601_DATETIME"
  | "SHORT_DATE"
  | "SHORT_DATETIME"
  | "LONG_DATE"
  | "LONG_DATETIME"
  | "FULL_DATETIME"
  | "TIME"
  | "SHORT_DATETIME_W_SEC"
  | "LONG_MONTH_YEAR"
  | "SHORT_MONTH_YEAR"
  | "LONG_DATETIME_WITH_TIMEZONE"
  | "SHORT_DATETIME_WITH_TIMEZONE"
  | "FULL_DATETIME_WITH_TIMEZONE"

export enum MenuStyles {
  /** Show menu as in a dropdown */
  Dropdown,
  /** Show menu items inline */
  ActionItems,
  /** Only show first menu item with faExternalLink icon */
  ExternalAction,
}

export type PractitionerInfo = {
  practitioner: Practitioner
  practitionerRole: PractitionerRole
  practitionerRoleRef?: Reference
  practitionerRoles?: PractitionerRole[]
  hasDosespotSetup: boolean
}

export enum RoundedStyles {
  None = 0,
  Left = 1,
  Right = 2,
  Both = Left | Right,
  Full_Left = 4,
  Full_Right = 8,
  Full = Full_Left | Full_Right,
}

export type GroupedChargeItemDefinitionsCodes = {
  billToPracticeOrInsuranceCIDs?: (ParametersParameterArrayValue | Coding)[]
  billToPatientCIDs?: (ParametersParameterArrayValue | Coding)[]
}

export type ChargeItemDefinitions = {
  billToPracticeOrInsuranceCIDs: Record<string, ChargeItemDefinition>
  billToPatientCIDs: Record<string, ChargeItemDefinition>
}

export type CustomError = Error & { cause: { name: string; message: string; logoutFlag?: boolean; traceId?: string } }

export type FieldErrorType = [string, string | object | []]

export type MedicationRequestData = {
  medicationRequestInfo: MedicationRequestInfo
  medicationKnowledge: MedicationKnowledge | undefined
  pricePerUnit?: Money
  medicationDispense?: MedicationDispense[]
  serviceRequest?: ServiceRequest
  patientPrice?: Money
  requesterRef?: Reference
}

export type MedicationRequestInfo = MedicationRequest & {
  //nutra props
  medicationUnit?: string
  doseQuantity?: string
  treatmentFrequency?: string
  unitsByRecipient?: number
  //Rx props
  prescriptionQuantity?: Quantity
  dosages?: MedicationDosage[]
  administrationGuideline?: MedicationKnowledgeAdministrationGuidelinesArray
  //commons props
  requesterRef?: Reference
}
