import { faPrescription } from "@fortawesome/pro-regular-svg-icons"
import { ChargeItemDefinition, MedicationKnowledge, MedicationRequest } from "fhir"
import { useId, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useSearchParams } from "react-router-dom"

import {
  EmptyMessage,
  MedicationRequestData,
  MedicationRequestInfo,
  SkeletonLoader,
  Slideover,
  StackedListContainer,
  useChargeItemDefinitions,
  useWindowBounds,
} from "commons"
import {
  completeMR,
  getFeeType,
  MedicationRequestDetails,
  stopMR,
  useDeleteMedicationRequest,
  useGetMedicationsProductPrices,
  useMedicationRequests,
  useStopMedicationRequest,
  MedicationDetails,
} from "commons/meds"
import { BILLING_TYPES_CODES, MED_FEE_TYPE } from "data"
import { useLoginContext } from "security"
import { getBillingTypeCode, getCommonCode, getIndexedCID, getMedCodes, getPriceByCode } from "utils"

import { discardMR } from "../helpers"
import { useCompleteMrOrder, useMedicationRequestDataBind } from "../hooks"
import { prescriptionItemModel } from "./prescriptionItemModel"

const PrescriptionList = ({ searchFilter, statusFilter }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { isSmallScreen } = useWindowBounds()
  const { managingOrganizationId, loggedInPatientId: patientId } = useLoginContext()
  const {
    medicationRequestsInfo,
    medicationKnowledges,
    medicationDispenses,
    isLoading,
    hasNextPage,
    fetchNextPage,
    reloadMedications,
  } = useMedicationRequests(patientId as string, "medication", statusFilter ?? [], 100, 1, searchFilter)

  const [selectedMK, setSelectedMK] = useState<MedicationRequest & { medicationKnowledge?: MedicationKnowledge }>()

  const { billToPatientMrs } = medicationRequestsInfo?.reduce(
    (acc, mr) => {
      const billingTypeCode = getBillingTypeCode(mr)

      return billingTypeCode === undefined
        ? {
            billToPatientMrs: [...acc.billToPatientMrs, mr],
          }
        : billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
          ? {
              billToPatientMrs: [...acc.billToPatientMrs, mr],
            }
          : {
              billToPatientMrs: acc.billToPatientMrs,
            }
    },
    { billToPatientMrs: [] as MedicationRequest[] },
  ) ?? { billToPatientMrs: [] }

  const billToPatientMedicationKnowledgeCodes = billToPatientMrs
    ? getMedCodes({ meds: billToPatientMrs, withQty: true })
    : undefined

  const { chargeItemDefinitions: practiceChargeItemDefinitions } = useChargeItemDefinitions(managingOrganizationId, {
    billToPracticeOrInsuranceCIDs: billToPatientMedicationKnowledgeCodes,
  })

  const { chargeItemDefinitions: patientChargeItemDefinitions } = useGetMedicationsProductPrices({
    organizationId: managingOrganizationId,
    codes: {
      billToPatientCIDs: billToPatientMedicationKnowledgeCodes,
    },
  })

  const { medicationRequestData: mrDataWithoutPrices } = useMedicationRequestDataBind(
    medicationRequestsInfo,
    medicationKnowledges,
    (patientChargeItemDefinitions?.billToPatientCIDs as Record<string, ChargeItemDefinition[]>) ?? {},
    medicationDispenses,
  )

  const { deleteMedicationRequest } = useDeleteMedicationRequest()
  const { stopMedicationRequest } = useStopMedicationRequest({ refreshMedications: reloadMedications })
  const { completeMedicationRequest } = useCompleteMrOrder()

  const medicationRequestData = mrDataWithoutPrices.map((item) => {
    const billingTypeCode = getBillingTypeCode(item?.medicationRequestInfo)
    const skuCode = getCommonCode({ codes: item.medicationKnowledge?.code?.coding })

    const specificCIDs = patientChargeItemDefinitions?.billToPatientCIDs?.[skuCode] as ChargeItemDefinition[]
    const indexedCIDs = getIndexedCID(specificCIDs, true)

    const patientPrice = getPriceByCode({
      chargeItemDefinitions: indexedCIDs ?? {},
      medCoding: item.medicationKnowledge?.code?.coding,
      factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
      useMedFee: true,
      ...(getFeeType(specificCIDs) === MED_FEE_TYPE.ByFrequency
        ? { productFrequency: item.medicationRequestInfo.dispenseRequest?.dispenseInterval }
        : {}),
    })
    const practicePrice = getPriceByCode({
      chargeItemDefinitions:
        (practiceChargeItemDefinitions?.billToPracticeOrInsuranceCIDs as Record<string, ChargeItemDefinition>) ?? {},
      medCoding: item.medicationKnowledge?.code?.coding,
      factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
    })

    const priceInfo =
      billingTypeCode === undefined
        ? { patientPrice, practicePrice }
        : billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
          ? { patientPrice }
          : { practicePrice }

    return {
      ...item,
      ...priceInfo,
    }
  })

  const selectedMedicationRequestData = useMemo(() => {
    const mrId = searchParams.get("mrId")
    return medicationRequestData.find(({ medicationRequestInfo }) => medicationRequestInfo.id === mrId)
  }, [searchParams, medicationRequestData])

  const discard = (mrId: string) => {
    discardMR(mrId, () => {
      deleteMedicationRequest(mrId)
    })
  }
  const stop = (mrId: string) => {
    stopMR(mrId, () => {
      stopMedicationRequest([mrId])
    })
  }
  const complete = (mrId: string) => {
    completeMR(mrId, () => {
      completeMedicationRequest(mrId)
    })
  }

  const previewMedOrMK = (mrData: MedicationRequestData) => {
    setSelectedMK({ medicationKnowledge: mrData.medicationKnowledge, ...mrData.medicationRequestInfo })
  }

  const showMRDetails = (mr: MedicationRequestInfo) => {
    if (mr.id) {
      searchParams.set("mrId", mr.id)
      setSearchParams(searchParams)
    }
  }

  const hideMRDetails = () => {
    searchParams.delete("mrId")
    setSearchParams(searchParams)
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) {
    return loader()
  }

  if (medicationRequestData.length === 0) {
    return <EmptyMessage icon={faPrescription} itemTitle="Prescription" />
  }

  return (
    <>
      <div className="h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={medicationRequestData}
            keyGenerator={({ medicationRequestInfo }) => medicationRequestInfo.id}
            itemModelBuilder={(item) =>
              prescriptionItemModel({
                mrData: item,
                discard,
                stop,
                complete,
                preview: previewMedOrMK,
                showDetails: showMRDetails,
              })
            }
          />
        </InfiniteScroll>
      </div>

      <MedicationDetails medication={selectedMK} onHide={() => setSelectedMK(undefined)} />
      <Slideover
        showSlide={!!selectedMedicationRequestData}
        onHide={hideMRDetails}
        title="Medication Request Details"
        showCancel
        cancelLabel="Close"
        dismissable
        showCloseIcon
        position={isSmallScreen ? "bottom" : "right"}
      >
        {selectedMedicationRequestData && (
          <MedicationRequestDetails
            medicationRequest={selectedMedicationRequestData.medicationRequestInfo}
            medicationDispense={selectedMedicationRequestData.medicationDispense}
          />
        )}
      </Slideover>
    </>
  )
}

type Props = { statusFilter?: string[]; searchFilter?: string }

export { PrescriptionList }
