import { compareDesc, parseISO } from "date-fns"
import { ChargeItemDefinition, MedicationDispense, MedicationKnowledge, ServiceRequest } from "fhir"
import { useMemo } from "react"

import { formatsByTypes } from "data"
import { formatDate, getBasePrice, getCidIdentifier, getCommonCode } from "utils"
import { MedicationRequestData, MedicationRequestInfo } from "commons"

const useMedicationRequestDataBind = (
  medicationRequests?: MedicationRequestInfo[],
  medicationKnowledges?: Record<string, MedicationKnowledge>,
  medicationsCIDs?: Record<string, ChargeItemDefinition>,
  medicationDispenses?: MedicationDispense[],
  serviceRequests?: ServiceRequest[],
) => {
  const { data } = useMemo(() => {
    const data = (medicationRequests ?? []).reduce((acc, mr) => {
      const newMRD: MedicationRequestData = getMedicationData({
        medicationDispenses,
        medicationKnowledges,
        medicationsCIDs,
        medicationRequest: mr,
        serviceRequests,
      })
      /* const data = medicationRequests?.reduce((acc, mr) => {
      const medicationDispense = medicationDispenses?.filter((md) => md.authorizingPrescription?.[0].id === mr.id)
      const mrCode = getCommonCode(mr.medication?.CodeableConcept?.coding)

      const medicationKnowledge = medicationKnowledges?.find((mk) => getCommonCode(mk.code?.coding) === mrCode)
      const chargeItemDefinition = medicationsCIDs?.[getCidIdentifier(mrCode, mr?.dispenseRequest?.quantity?.value)]

      const newMRD: MedicationRequestData = {
        medicationRequestInfo: { ...mr, ...{ medicationUnit: medicationKnowledge?.amount?.unit } },
        medicationKnowledge,
        medicationDispense,
        pricePerUnit: chargeItemDefinition?.propertyGroup?.[0].priceComponent?.[0].amount,
      } */

      return [...acc, newMRD]
    }, new Array<MedicationRequestData>())

    return { data }
  }, [medicationRequests, medicationKnowledges, medicationsCIDs, medicationDispenses, serviceRequests])

  return { medicationRequestData: data }
}

const useMedicationRequestBindByDate = (
  medicationRequests?: MedicationRequestInfo[],
  medicationKnowledges?: Record<string, MedicationKnowledge>,
  medicationsCIDs?: Record<string, ChargeItemDefinition>,
  medicationDispenses?: MedicationDispense[],
  searchFilter?: string,
) => {
  const { data } = useMemo(() => {
    const initialMap = new Map<string, MedicationRequestData[]>([["Unspecified", []]])
    const medRequests = searchFilter
      ? medicationRequests?.filter((mr) => mr.medication?.CodeableConcept?.coding?.[0].display?.includes(searchFilter))
      : medicationRequests

    const data = medRequests?.reduce((map, mr) => {
      const newMRD: MedicationRequestData = getMedicationData({
        medicationDispenses,
        medicationKnowledges,
        medicationsCIDs,
        medicationRequest: mr,
      })

      if (
        mr.dispenseRequest?.nextRefillDate &&
        (mr.dispenseRequest?.numberOfRepeatsAllowed as number) + 1 > (medicationDispenses?.length ?? 0)
      ) {
        const refillDate = formatNextRefillDate(mr.dispenseRequest?.nextRefillDate)

        const value = map.get(refillDate)
        if (value) {
          value.push(newMRD)
        } else {
          map.set(refillDate, [newMRD])
        }
      }

      return map
    }, initialMap)

    if (!data?.get("Unspecified")?.length) {
      data?.delete("Unspecified")
    }

    return {
      data: data
        ? Array.from(data.entries()).map(([date, entries]) => ({
            date,
            medicationsData: entries,
            mrIds: entries.map((md) => md.medicationRequestInfo.id ?? ""),
          }))
        : [],
    }
  }, [medicationRequests, medicationKnowledges, medicationsCIDs, medicationDispenses, searchFilter])

  return { medicationRequestDataByDate: data }
}

const useMedicationRequestGroupByDate = (
  medicationRequestsInfo?: MedicationRequestInfo[],
  medicationKnowledges?: Record<string, MedicationKnowledge>,
  medicationsCIDs?: Record<string, ChargeItemDefinition>,
  medicationDispenses?: MedicationDispense[],
  searchFilter?: string,
) => {
  const { data } = useMemo(() => {
    const initialMap = new Map<string, MedicationRequestData[]>([["Unspecified", []]])
    const medRequests = searchFilter
      ? medicationRequestsInfo?.filter((mr) =>
          mr.medication?.CodeableConcept?.coding?.[0].display?.includes(searchFilter),
        )
      : medicationRequestsInfo

    const data = medRequests?.reduce((map, mr) => {
      const newMRD: MedicationRequestData = getMedicationData({
        medicationDispenses,
        medicationKnowledges,
        medicationsCIDs,
        medicationRequest: mr,
      })

      if (mr.dispenseRequest?.nextRefillDate) {
        const refillDate = formatNextRefillDate(mr.dispenseRequest?.nextRefillDate).split("T")[0]

        const value = map.get(refillDate)
        if (value) {
          value.push(newMRD)
        } else {
          map.set(refillDate, [newMRD])
        }
      }

      return map
    }, initialMap)

    if (!data?.get("Unspecified")?.length) {
      data?.delete("Unspecified")
    }

    return {
      data: data
        ? Array.from(data.entries())
            .sort((mr1, mr2) => compareDesc(mr1[0], mr2[0]))
            .map(([date, entries]) => ({
              key: formatDate(parseISO(date), formatsByTypes.LONG_DATE),
              name: formatDate(parseISO(date), formatsByTypes.LONG_DATE),
              items: entries,
            }))
        : [],
    }
  }, [medicationRequestsInfo, medicationKnowledges, medicationsCIDs, medicationDispenses, searchFilter])

  return { medicationRequestDataGroupByDate: data }
}

const getMedicationData = ({
  medicationDispenses,
  medicationKnowledges,
  medicationRequest: mr,
  medicationsCIDs,
  serviceRequests,
}: DataProps) => {
  const medicationDispense = medicationDispenses?.filter((md) => md.authorizingPrescription?.[0].id === mr.id)
  const mrCode = getCommonCode({ codes: mr?.medication?.CodeableConcept?.coding })

  const medicationKnowledge = medicationKnowledges?.[mrCode]
  const chargeItemDefinition = medicationsCIDs?.[getCidIdentifier(mrCode, mr?.dispenseRequest?.quantity?.value)]

  const serviceRequest = serviceRequests?.find((sr) => sr.basedOn?.find((ref) => ref.id === mr.id))

  const newMRD: MedicationRequestData = {
    medicationRequestInfo: {
      ...mr,
      ...{ medicationUnit: medicationKnowledge?.amount?.unit, unitsByRecipient: medicationKnowledge?.amount?.value },
    },
    medicationKnowledge,
    medicationDispense,
    pricePerUnit: getBasePrice(chargeItemDefinition?.propertyGroup?.[0].priceComponent)?.amount,
    serviceRequest,
  }

  return newMRD
}

const formatNextRefillDate = (refillDate?: string) => {
  return refillDate ? new Date(refillDate).toISOString() : "Unspecified"
}

type DataProps = {
  medicationKnowledges?: Record<string, MedicationKnowledge>
  medicationsCIDs?: Record<string, ChargeItemDefinition>
  medicationDispenses?: MedicationDispense[]
  serviceRequests?: ServiceRequest[]
  medicationRequest: MedicationRequestInfo
}

export { useMedicationRequestBindByDate, useMedicationRequestDataBind, useMedicationRequestGroupByDate }
