import {
  ChargeItemDefinition,
  codeableConceptAsString,
  Duration,
  MedicationDispense,
  MedicationKnowledge,
  MedicationRequest,
  ParametersParameterArrayArray,
  ParametersParameterArrayValue,
} from "fhir"
import { confirmDialog } from "primereact/confirmdialog"

import { MED_FEE_TYPE } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getCommonCode } from "utils"

import { MedicationRequestData } from "../types"
import { dispenseInterval } from "./data"

const getAutoCompleteMedFieldText = (e?: MedicationKnowledge) => {
  const textCode = codeableConceptAsString(e?.code)
  const packagingText = e?.packaging
    ? `${e?.packaging?.quantity?.value ?? "unspecified"} ${e?.packaging?.quantity?.unit ?? "unspecified"}`
    : ""

  const value = `${textCode} - ${packagingText}`
  return value
}

const getDispenseFrequency = (interval: Duration | undefined) => {
  const dispense =
    interval &&
    dispenseInterval.find(
      ({ value: { code, system, unit, value } }) =>
        interval?.system === system && interval?.code === code && interval?.value === value && interval?.unit === unit,
    )

  return dispense
}

// TODO: Check this code back with meds team to improve implementation
const hasInvalidMedicationDispense = (medicationDispenses?: MedicationDispense[]) =>
  Boolean(
    medicationDispenses?.some((md) => md.statusReason?.CodeableConcept?.coding?.[0]?.code === "Invalid Submission"),
  )

const stopMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to stop this prescription?",
    header: "Confirmation",
    acceptLabel: "Stop",
    rejectLabel: "Cancel",
    style: { width: "50vw " },
    breakpoints: { "1280px": "70vw", "1024px": "80vw", "640px": "90vw" },
    rejectClassName: "button-default",
    acceptClassName: "button-primary",
    accept: () => onAcceptCliked(medReqId),
  })
}

const refreshMR = (updatedMR: MedicationRequest, medicationsList: MedicationRequestData[]) => {
  const index = medicationsList?.findIndex((mrData) => mrData.medicationRequestInfo.id === updatedMR.id)

  if (index !== -1) {
    const updatedMedData = {
      ...medicationsList[index],
      ...{ medicationRequestInfo: { ...medicationsList[index].medicationRequestInfo, ...updatedMR } },
    }
    medicationsList.splice(index, 1, updatedMedData)
  }
}

const onCancel = (id: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to cancel this order?",
    header: "Confirmation",
    acceptLabel: "Ok",
    rejectLabel: "Cancel",
    rejectClassName: "button-default",
    acceptClassName: "button-primary",
    accept: () => {
      onAcceptCliked(id)
    },
  })
}

const completeMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to mark this prescription as completed?",
    header: "Confirmation",
    acceptLabel: "Complete",
    rejectLabel: "Cancel",
    style: { width: "50vw " },
    breakpoints: { "1280px": "70vw", "1024px": "80vw", "640px": "90vw" },
    rejectClassName: "button-default",
    acceptClassName: "button-primary",
    accept: () => onAcceptCliked(medReqId),
  })
}

const getMKDisplayText = (e?: MedicationKnowledge) => {
  const textCode = codeableConceptAsString(e?.code)
  const packagingText =
    !!e?.packaging?.type?.coding?.[0]?.display &&
    !!e?.packaging?.quantity?.value &&
    `${e?.packaging?.type?.coding?.[0]?.display}`
  const strength = e?.ingredient?.length === 1 && e?.ingredient[0]?.strength?.numerator?.unit
  const doseForm = e?.doseForm?.coding?.[0]?.display

  const displayArray = [textCode, strength, doseForm, packagingText].filter(Boolean)

  return `${displayArray.join(" - ")}`
}

const getStatusClass = (status: string) => {
  switch (status) {
    case "active":
      return "is-success"
    case "stopped":
      return "is-danger"
    case "completed":
      return "is-info"
    case "cancelled":
      return "is-warning"
    case "draft":
      return "is-warning"
    default:
      return ""
  }
}

const getIndexedCIDBySku = (cids?: ChargeItemDefinition[]) =>
  cids?.reduce<Record<string, ChargeItemDefinition[]>>(
    (acc, cid) => {
      const skuCode = getCommonCode({ codes: cid.code?.coding })

      return { ...acc, [skuCode]: [...(acc[skuCode] ?? []), cid] }
    },
    {} as { [key: string]: ChargeItemDefinition[] },
  ) ?? {}

const getProductConfig = (product: ParametersParameterArrayValue) =>
  ({
    name: "config-details",
    part: [
      {
        name: "quantity",
        value: { decimal: product?.Quantity?.value },
      },
      ...(product?.Duration
        ? [
            {
              name: "frequency",
              value: { Quantity: product?.Duration },
            },
          ]
        : []),
    ],
  }) as ParametersParameterArrayArray

const getParamsSkuCodesByFee = (codes: ParametersParameterArrayValue[]) => {
  const skuCodes = codes?.filter(
    (code) =>
      (code as ParametersParameterArrayValue)?.Coding?.system === SYSTEM_VALUES.SKU ||
      (code as ParametersParameterArrayValue)?.Coding?.system === SYSTEM_VALUES.SKU_CA,
  )

  const uniqueIdentifiers = new Set<string>(...[skuCodes?.map((code) => `${code?.Coding?.code}`)])

  const { groupedItems } = Array.from(uniqueIdentifiers).reduce(
    (acc, item) => {
      const itemsWithSameSku = skuCodes.filter((code) => code?.Coding?.code === item)

      itemsWithSameSku.map((currentItem) => {
        const key = `${currentItem?.Coding?.code}`

        if (!acc.groupedItems[key]) {
          acc.groupedItems[key] = {
            name: "product-info",
            part: [
              {
                name: "code",
                value: {
                  Coding: currentItem.Coding,
                },
              },
              {
                name: "product-configs",
                part: [getProductConfig(currentItem)],
              },
            ],
          }
        } else {
          const currentProductConfigsPart = [...(acc.groupedItems[key]?.part?.[1].part ?? [])]
          acc.groupedItems[key].part![1].part = [...currentProductConfigsPart, getProductConfig(currentItem)]
        }
      })
      return acc
    },
    {
      groupedItems: {} as { [key: string]: ParametersParameterArrayArray },
    },
  )

  return [...Object.values(groupedItems)] as ParametersParameterArrayArray[]
}

const getFeeType = (cids: ChargeItemDefinition[]) =>
  cids?.some(
    (cid) =>
      cid?.useContext?.find((context) => context?.code?.code === "bill-patient-fee-type")?.value?.CodeableConcept
        ?.coding?.[0]?.code === "by-frequency",
  )
    ? MED_FEE_TYPE.ByFrequency
    : MED_FEE_TYPE.Fixed

export {
  completeMR,
  getAutoCompleteMedFieldText,
  getDispenseFrequency,
  getFeeType,
  getIndexedCIDBySku,
  getMKDisplayText,
  getParamsSkuCodesByFee,
  getStatusClass,
  hasInvalidMedicationDispense,
  onCancel,
  refreshMR,
  stopMR,
}
